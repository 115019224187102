

    import { computed, defineComponent } from 'vue'
    import Heading from '../Heading.vue'
    import Paragraph from '../Paragraph.vue'
    import Quote from '../Blog/Quote.vue'
    import BlogImage from '../Blog/BlogImage.vue'
    import Grid from '../Grid/Grid.vue'
    import Column from '../Grid/Column.vue'

    export default defineComponent({
        name: 'BlogContent',
        components: {
            Heading,
            Paragraph,
            Quote,
            BlogImage,
            Grid,
            Column
        },
        props: {
            data: { type: Object, required: true },
            index: { type: Number, required: true }
        },
        setup(props) {

            const isQuote = props.data.type === 'quote'
            const isHeading = props.data.type === 'heading'
            const isImage = props.data.type === 'image'
            const isParagraph = props.data.type === 'paragraph'

            return {
                isImage,
                component: computed(() => {

                    let content = {
                        is: props.data.type
                    } as any

                    if (isQuote) {

                        content = {
                            ...content,
                            params: {
                                author: props.data.author,
                                text: props.data.content,
                                class: [ { 'mt-12': props.index !== 0 } ]
                            }
                        }

                    } else {

                        content = {
                            ...content,
                            html: isHeading ? props.data.heading : props.data.paragraph
                        }

                        if (isHeading) {

                            content = {
                                ...content,
                                params: {
                                    tag: 'h2',
                                    class: [
                                        'overflow-hidden',
                                        { 'mt-24 md:mt-48': props.index !== 0 }
                                    ]
                                }
                            }

                        }

                        if (isParagraph) {

                            content = {
                                ...content,
                                params: {
                                    innerSpace: true,
                                    class: {
                                        'mt-12': props.index !== 0
                                    }
                                }
                            }

                        }

                    }

                    return content

                })
            }

        }
    })

