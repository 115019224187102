

    import { defineComponent, getCurrentInstance, ref, watchEffect, nextTick } from 'vue'
    import Container from '../components/Container.vue'
    import Grid from '../components/Grid/Grid.vue'
    // import BlockRevealAnimation from '../components/BlockRevealAnimation.vue'
    import PageHeader from '../components/PageHeader.vue'
    import Picture from '../components/Picture.vue'
    import BlogContent from '../components/Blog/BlogContent.vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { Theme } from '../enums/Theme'
    import { useCms } from '../compositions/UseCms'
    import { useTheme } from '../compositions/UseTheme'
    import { useRoute } from 'vue-router'

    export default defineComponent({
        name: 'BlogPost',
        components: {
            // BlockRevealAnimation,
            Container,
            Grid,
            BlogContent,
            PageHeader,
            Picture,
            MetaInfo,
            ThemeChanger
        },
        setup() {

            const route = useRoute()
            const { blogs, isCNLocale } = useCms()
            const refreshAOSHard = getCurrentInstance()?.appContext.config.globalProperties.$aos?.refreshHard
            const post = ref<any>('')
            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            watchEffect(() => {

                const path = isCNLocale.value ? `/cms/cn/blogs/${ route.params.slug }.json` : `/cms/en/blogs/${ route.params.slug }.json`

                fetch(path)
                    .then(response => response.json())
                    .then(response => {

                        post.value = response as any

                    })
                    .then(nextTick)
                    .then(() => {

                        refreshAOSHard?.()

                    })

            })

            return {
                Theme,
                cms: blogs,
                post
            }

        }
    })

