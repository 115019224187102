import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center ow-post__cover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Container = _resolveComponent("Container")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_BlogContent = _resolveComponent("BlogContent")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Default
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Container, {
          fluid: "",
          class: "pt-32 md:pt-64"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PageHeader, {
              header: _ctx.post?.title,
              "sub-header": `${ _ctx.post?.updated_at } · Written by ${ _ctx.post?.author?.name }`,
              "header-span": 5
            }, null, 8, ["header", "sub-header"])
          ]),
          _: 1
        }),
        (_ctx.post.cover)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 0,
              fluid: "",
              class: "pt-24"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_Picture, {
                    image: _ctx.post.cover,
                    height: "100%",
                    maxHeight: "100%",
                    width: "100%",
                    maxWidth: "100%",
                    lazy: false
                  }, null, 8, ["image"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Container, {
          class: "mt-24",
          fluid: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.post.body, (item, index) => {
              return (_openBlock(), _createBlock(_component_BlogContent, {
                key: index,
                data: item,
                index: index
              }, null, 8, ["data", "index"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.post.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.post.seo,
          "structured-data": _ctx.post.seo_structured_data
        }, null, 8, ["seo", "structured-data"]))
      : _createCommentVNode("", true)
  ], 64))
}